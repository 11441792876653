import React from 'react'
import '../assets/nav.css'
import {  Dropdown } from 'react-bootstrap';
import RosterController from '../controllers/roster'

export default class NavigationBar extends React.Component {
    render() {
        return(
        <div>
            <nav className="navbar navbar-expand-sm navbar-dark bg-primary">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    {/* On Small Screans */}
                    <div className="d-lg-none d-md-none  d-sm-block text-dark profile m-1 mt-0 mb-0" type="button" >EK</div>
                    <div className="d-lg-none d-md-none d-sm-block text-light fw-bolder">
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                Englon Kavhuru
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Sign Out</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">My Shift(s)</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                 
                    <div className="collapse navbar-collapse" id="mynavbar">
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item ">
                                <a className="nav-link text-light fw-bolder" href={`${RosterController.extractToken().main_system}`}> Tarisa</a>
                            </li>
                            {/* <li className="nav-item ">
                                <a className="nav-link text-light fw-bolder" href={`/dashboard?name=${RosterController.extractToken().name}&token=${RosterController.extractToken().token}&api=${RosterController.extractToken().api}&main_system=${RosterController.extractToken().main_system}`}> My Dashboard</a>
                            </li> */}
                            <li className="nav-item">
                                <a className="nav-link text-light fw-bolder " href={`/roster?name=${RosterController.extractToken().name}&token=${RosterController.extractToken().token}&api=${RosterController.extractToken().api}&main_system=${RosterController.extractToken().main_system}`}>Roster</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-light fw-bolder" href={`/participants?name=${RosterController.extractToken().name}&token=${RosterController.extractToken().token}&api=${RosterController.extractToken().api}&main_system=${RosterController.extractToken().main_system}`}>Participants</a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link text-light fw-bolder" href={`/reports?name=${RosterController.extractToken().name}&token=${RosterController.extractToken().token}&api=${RosterController.extractToken().api}&main_system=${RosterController.extractToken().main_system}`}>Reports</a>
                            </li> */}
                            <li className="nav-item">
                                <a className="nav-link text-light fw-bolder" href={`/audit?name=${RosterController.extractToken().name}&token=${RosterController.extractToken().token}&api=${RosterController.extractToken().api}&main_system=${RosterController.extractToken().main_system}`}>System Audit</a>
                            </li>
                        </ul>
                
                        <div className="small-screen text-dark profile m-2 mt-0 mb-0" type="button" >EK </div>
                        <div className=" large-screen text-light fw-bolder m-4 mt-0 mb-0">
                            <Dropdown>
                                <Dropdown.Toggle variant="" className="text-light" style={{"font-weight":"bolder"}} id="dropdown-basic">
                                    {RosterController.extractToken().name}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Sign Out</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">My Shift(s)</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        </div>                        
                </div>
            </nav>
        </div>
        );
    }
}